import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectStyles } from '../../../../helpers/object.helpers'
import { ObjectStylesWithId } from '../../../../hooks/editor/useWrapSelectedObjects'
import { ObjectsStateT } from '../../objects.slice'

const findCommonParentId = (objects: AnySceneObjectT[], selectedIds: string[]): string | null => {
    const getParent = (id: string): string | null =>
        objects.find((obj) => obj.id === id)?.parentId || null

    let parentSets: Set<string>[] = selectedIds.map((id) => {
        let path = new Set<string>()
        let current: string | null = id
        while (current) {
            current = getParent(current)
            if (current) path.add(current)
        }
        return path
    })

    if (parentSets.length === 0) return null

    let commonParents = Array.from(parentSets[0])

    for (let i = 1; i < parentSets.length; i++) {
        commonParents = commonParents.filter((p) => parentSets[i].has(p))
    }

    return commonParents.length > 0 ? commonParents[0] : null
}

export const groupObjectsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{
        objects: AnySceneObjectT[]
        groupObject: AnySceneObjectT
        wrapperObject: ObjectStyles
        allObjectsInWrapper: ObjectStylesWithId[]
    }>
) => {
    const selectedObjects = action.payload.objects
    const { top, left } = action.payload.wrapperObject
    const allObjectsInWrapper = action.payload.allObjectsInWrapper
    const childIds: string[] = []
    const selectedIds = selectedObjects.map((x) => x.id)

    let commonParentId = findCommonParentId(Object.values(state.value), selectedIds)

    selectedObjects.map((x) => childIds.push(x.id))

    const newGroupObject = action.payload.groupObject

    state.value = {
        ...state.value,
        [newGroupObject.id]: {
            ...newGroupObject,
            childIds: childIds,
            parentId: commonParentId ? commonParentId : null,
        },
    }
    if (commonParentId) {
        state.value = {
            ...state.value,
            [commonParentId]: {
                ...state.value[commonParentId],
                childIds: [...state.value[commonParentId].childIds, newGroupObject.id],
            },
        }
    }
    selectedObjects.forEach((object) => {
        const topStyle: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === 'top'
        )
        const leftStyle: SimpleStyleT | undefined = object.styles.find(
            (style) => style.property === 'left'
        )
        const restStyles = object.styles.filter(
            (style) => style.property !== 'top' && style.property !== 'left'
        )
        if (!topStyle || !leftStyle) return state.value
        if (object.parentId) {
            state.value = {
                ...state.value,
                [object.parentId]: {
                    ...state.value[object.parentId],
                    childIds: state.value[object.parentId].childIds.filter(
                        (child) => child !== object.id
                    ),
                },
            }
        }

        state.value = {
            ...state.value,
            [object.id]: {
                ...state.value[object.id],
                parentId: newGroupObject.id,
                isLocked: true,
                styles: [
                    ...restStyles,
                    {
                        ...topStyle,
                        value:
                            Number(allObjectsInWrapper.find((x) => x.objectId === object.id)?.top) -
                            top,
                    },
                    {
                        ...leftStyle,
                        value:
                            Number(
                                allObjectsInWrapper.find((x) => x.objectId === object.id)?.left
                            ) - left,
                    },
                ],
            },
        }
    })
    state.updatedAt = Date.now()
}
