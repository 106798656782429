import { t } from 'i18next'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { setOpenAlertTestGraphicDialog } from '../../../store/slices/editor.slice'
import AlertMoreChoiceDialog from '../../common/AlertMoreChoiceDialog'
interface Props {
    noSaveContinues: () => void
    saveContinues: () => void
}
const AlertTestGraphicDialog = ({ noSaveContinues, saveContinues }: Props) => {
    const dispatch = useAppDispatch()
    const openAlertTestGraphicDialog = useAppSelector(
        (state) => state.editor.value.openAlertTestGraphicDialog
    )
    const handleClose = () => {
        dispatch(setOpenAlertTestGraphicDialog(false))
    }
    return (
        <AlertMoreChoiceDialog
            open={openAlertTestGraphicDialog}
            title={`${t('editor:titleAlertTestDialogChanges')}`}
            content={t('editor:contentAlertTestDialogChanges')}
            onClose={handleClose}
            buttonArray={[
                {
                    key: 'disagree',
                    onclick: handleClose,
                    txt: t('disagree'),
                    variant: 'text',
                    color: 'inherit',
                },
                {
                    key: 'continue-without-saving',
                    onclick: noSaveContinues,
                    txt: t('editor:continueWithoutSaving'),
                    variant: 'text',
                    color: 'inherit',
                },
                {
                    key: 'save-changes',
                    onclick: saveContinues,
                    txt: t('editor:saveChanges'),
                    variant: 'contained',
                    color: 'primary',
                },
            ]}
        />
    )
}

export default AlertTestGraphicDialog
