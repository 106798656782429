import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import config from '../../../config'
import { selectCanUndo } from '../../../helpers/selector.helpers'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { setOpenAlertTestGraphicDialog } from '../../../store/slices/editor.slice'

interface Prop {
    label: string
    disabled: boolean
    onClick: () => void
}
function TestGraphic({ onClick, disabled, label }: Prop) {
    const dispatch = useAppDispatch()
    const graphic = useAppSelector((state) => state.graphic.data)
    const canUndo = useAppSelector((state) => selectCanUndo(state))

    const handleOpenTextGraphic = () => {
        if (canUndo) {
            dispatch(setOpenAlertTestGraphicDialog(true))
        } else {
            const link = `${config.testApp}?graphicId=${graphic._id}`
            window.open(link, '_blank')
        }
        onClick()
    }

    return (
        <MenuItem key="testGraphic" onClick={handleOpenTextGraphic} disabled={disabled}>
            <ListItemIcon>
                <RoomPreferencesIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

export default TestGraphic
