import { defaultGroupWrapper } from '../../data/defaults/objects.types.defaults'
import { createObject } from '../../helpers/creators.helpers'
import { selectUndoable } from '../../helpers/selector.helpers'
import { clearAllSelectedObjectsAction } from '../../store/slices/activeObject.slice'
import {
    delMultiObjectsAction,
    duplicateObjects,
    groupObjectsActionAsync,
    selectObjectsByIds,
} from '../../store/slices/objects.slice'
import { useAppDispatch, useAppSelector } from '../useRedux'
import useWrapSelectedObject from './useWrapSelectedObject'
import useWrapSelectedObjects from './useWrapSelectedObjects'

const useMultiselectObjectAction = () => {
    const dispatch = useAppDispatch()
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)

    const objects = useAppSelector((state) => selectUndoable(state).objects)
    const { wrapperObject, allObjectsInWrapper } = useWrapSelectedObjects()
    const { wrapperObjectsStyles } = useWrapSelectedObject()

    const handleDuplicateObjects = () => {
        dispatch(duplicateObjects(selectObjectsByIds(objects, selectedObjectIds)))
    }

    const handleDeleteObjects = () => {
        dispatch(delMultiObjectsAction(selectObjectsByIds(objects, selectedObjectIds)))
        dispatch(clearAllSelectedObjectsAction())
    }
    const handleGroupObjects = () => {
        if (selectedObjectIds.length === 1) {
            const { width, height, top, left } = wrapperObjectsStyles
            const groupObject = createObject(
                Object.values(objects.value),
                defaultGroupWrapper({ width, height, top, left }),
                null
            )
            dispatch(
                groupObjectsActionAsync(
                    groupObject,
                    selectObjectsByIds(objects, selectedObjectIds),
                    wrapperObjectsStyles,
                    [wrapperObjectsStyles]
                )
            )
        } else {
            const { width, height, top, left } = wrapperObject
            const groupObject = createObject(
                Object.values(objects.value),
                defaultGroupWrapper({ width, height, top, left }),
                null
            )
            dispatch(
                groupObjectsActionAsync(
                    groupObject,
                    selectObjectsByIds(objects, selectedObjectIds),
                    wrapperObject,
                    allObjectsInWrapper
                )
            )
        }
    }

    return {
        handleDuplicateObjects,
        handleDeleteObjects,
        handleGroupObjects,
    }
}

export default useMultiselectObjectAction
