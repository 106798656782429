import { backgroundColor, backgroundImage } from '../styles/groups/fill.styles'
import {
    matrix,
    rotateX,
    rotateY,
    rotateZ,
    scaleX,
    scaleY,
    transformOrigin,
    translateX,
    translateY,
} from '../styles/groups/transform.styles'
import {
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
} from '../styles/simple/border.styles'
import {
    backgroundPosition,
    backgroundRepeat,
    backgroundSize,
    color,
} from '../styles/simple/colorAndBackground.styles'
import { display, opacity, overflow } from '../styles/simple/display.styles'
import { alignItems, flexDirection, justifyContent } from '../styles/simple/flex.styles'
import {
    direction,
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    lineHeight,
    textAlign,
    textTransform,
    whiteSpace,
} from '../styles/simple/font.styles'
import {
    left,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    position,
    top,
    zIndex,
} from '../styles/simple/position.styles'
import { crawlTickerTextWidth, height, width } from '../styles/simple/size.styles'

const padding = [paddingTop, paddingBottom, paddingLeft, paddingRight]
const margin = [marginTop, marginBottom, marginRight, marginLeft]
const background = [backgroundSize, backgroundRepeat, backgroundPosition]
const flex = [flexDirection, alignItems, justifyContent]
const borderRadius = [
    borderBottomLeftRadius,
    borderBottomRightRadius,
    borderTopLeftRadius,
    borderTopRightRadius,
]
const font = [
    fontFamily,
    fontSize,
    fontStyle,
    fontWeight,
    textAlign,
    textTransform,
    direction,
    whiteSpace,
]

export const defaultTickerStyle: SimpleStyleT[] = [
    { ...width, value: 1920 },
    { ...height, value: 80 },
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    { ...overflow, value: 'hidden' },
    opacity,
    transformOrigin,
    ...borderRadius,
]
export const defaultTickerCrawlTextWrapperItemStyle: SimpleStyleT[] = [
    { ...width, value: 1920 },
    { ...height, value: 80 },
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]
export const defaultTickerScrollTextWrapperItemStyle: SimpleStyleT[] = [
    { ...width, value: 200 },
    { ...height, value: 80 },
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]
export const defaultScrollTickerTextStyle: SimpleStyleT[] = [
    ...font,
    { ...width, value: 1920 },
    { ...height, value: 80 },
    top,
    left,
    position,
    zIndex,
    {
        ...display,
        value: 'flex',
    },
    ...padding,
    ...margin,
    ...flex,
    { ...color, value: { r: 255, g: 255, b: 255, a: 1 } },
    opacity,
    transformOrigin,
]

const crawlPadding = [paddingTop, paddingBottom, paddingLeft, { ...paddingRight, value: 10 }]
export const defaultTickerCrawlTextStyle: SimpleStyleT[] = [
    ...font,
    crawlTickerTextWidth,
    { ...height, value: 80 },
    top,
    left,
    position,
    zIndex,
    {
        ...display,
        value: 'flex',
    },
    ...crawlPadding,
    ...margin,
    ...flex,
    { ...color, value: { r: 255, g: 255, b: 255, a: 1 } },
    opacity,
    transformOrigin,
]

export const defaultItemStyle: SimpleStyleT[] = [
    width,
    height,
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultTextStyle: SimpleStyleT[] = [
    ...font,
    width,
    height,
    top,
    left,
    position,
    zIndex,
    {
        ...display,
        value: 'flex',
    },
    ...padding,
    ...margin,
    ...flex,
    color,
    opacity,
    transformOrigin,
    lineHeight,
]

export const defaultImageStyle: SimpleStyleT[] = [
    width,
    height,
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultItemFills: GroupStyleI[] = [{ ...backgroundColor, index: 0 }]
export const defaultTickerFills: GroupStyleI[] = [
    { ...backgroundColor, value: { r: 50, g: 67, b: 73, a: 1 }, index: 0 },
]
export const defaultGroupFills: GroupStyleI[] = [
    { ...backgroundColor, value: { r: 0, g: 0, b: 0, a: 0 }, index: 0 },
]
export const defaultTickerTextWrapperItemFills: GroupStyleI[] = [
    { ...backgroundColor, value: { r: 255, g: 255, b: 255, a: 0 }, index: 0 },
]

export const defaultItemTransforms: GroupStyleI[] = [
    { ...scaleX, index: 0 },
    { ...scaleY, index: 1 },
    { ...translateX, index: 2 },
    { ...translateY, index: 3 },
    { ...rotateX, index: 4 },
    { ...rotateY, index: 5 },
    { ...rotateZ, index: 6 },
    { ...matrix, index: 7 },
    // skewX,
    // skewY,
]

export const defaultTextTransforms: GroupStyleI[] = defaultItemTransforms
export const defaultImageTransforms: GroupStyleI[] = []

export const defaultImageFills: GroupStyleI[] = [{ ...backgroundImage, index: 0 }]

export const defaultVideoPanelStyle = (widthValue: number, heightValue: number): SimpleStyleT[] => [
    { ...width, value: widthValue },
    { ...height, value: heightValue },
    top,
    left,
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultGroupStyle = (
    widthValue: number,
    heightValue: number,
    topValue: number,
    leftValue: number
): SimpleStyleT[] => [
    { ...width, value: widthValue },
    { ...height, value: heightValue },
    { ...top, value: topValue },
    { ...left, value: leftValue },
    position,
    zIndex,
    display,
    ...padding,
    ...margin,
    ...background,
    ...flex,
    overflow,
    opacity,
    transformOrigin,
    ...borderRadius,
]

export const defaultVideoPanelTransforms: GroupStyleI[] = [
    { ...scaleX, index: 0 },
    { ...scaleY, index: 1 },
    { ...translateX, index: 2 },
    { ...translateY, index: 3 },
    { ...rotateX, index: 4 },
    { ...rotateY, index: 5 },
    { ...rotateZ, index: 6 },
    { ...matrix, index: 7 },
    // skewX,
    // skewY,
]
