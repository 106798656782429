import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { isWindows } from 'react-device-detect'

interface Props {
    label: string
    disabled: boolean
    onClick: () => void
}

const DeleteGraphic = ({ label, disabled, onClick }: Props) => {
    const handleDeleteGraphic = () => {
        onClick()
    }
    return (
        <MenuItem key="clear" onClick={handleDeleteGraphic} disabled={disabled}>
            <ListItemIcon>
                <DeleteRoundedIcon />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
            <span style={{ fontSize: '12px' }}>{isWindows ? 'Ctrl Shift Del' : '⇧ ⌘ ⌫'}</span>
        </MenuItem>
    )
}

export default DeleteGraphic
