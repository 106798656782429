import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArticleIcon from '@mui/icons-material/Article'
import CollectionsIcon from '@mui/icons-material/Collections'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import ImageIcon from '@mui/icons-material/Image'
import LayersIcon from '@mui/icons-material/Layers'
import LayersClearIcon from '@mui/icons-material/LayersClear'
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import { t } from 'i18next'
import React, { ChangeEvent, MutableRefObject, useMemo, useState } from 'react'
import { isWindows } from 'react-device-detect'
import { FilterType } from '../../../constants/filterLists'
import useMultiselectObject from '../../../hooks/editor/useMultiselectObject'
import useMultiselectObjectAction from '../../../hooks/editor/useMultiselectObjectAction'
import useWrapSelectedObject from '../../../hooks/editor/useWrapSelectedObject'
import useWrapSelectedObjects from '../../../hooks/editor/useWrapSelectedObjects'
import useObject from '../../../hooks/useObject'
import { useObjectActions } from '../../../hooks/useObjectActions'
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux'
import { useTickerCreateAction } from '../../../hooks/useTickerCreateAction'
import { setLoading } from '../../../store/slices/loading.slice'
import {
    duplicateObjects,
    moveSelectedHereAction,
    moveSelectedToRootAction,
    unGroupObjectsAction,
} from '../../../store/slices/objects.slice'
import RightClickContextMenu, { MenuItemT } from '../../common/ContextMenu/RightClickContextMenu'
import AssetsDialog from './AssetsDialog'
import ImageSrcDialog from './ImageSrcDialog'
import LoadImageAction from './LoadImageAction'
import ObjectTitle from './ObjectTitle'

interface Props {
    object: AnySceneObjectT
    activeObjectId: string | null
    numberOfObjects: number
}

const ObjectsTreeNodeMenu = ({ object, activeObjectId, numberOfObjects }: Props) => {
    const [assetType, setAssetType] = useState<FilterType | undefined>()

    const dispatch = useAppDispatch()
    const selectedObjectIds: string[] = useAppSelector((state) => state.activeObject.selected)
    const [openImageSrcDialog, setOpenImageSrcDialog] = useState<boolean>(false)
    const {
        isParentObjectType,
        addChildItem,
        addChildText,
        deleteObject,
        moveObjectUp,
        moveObjectDown,
    } = useObjectActions()
    const { findObjectById } = useObject()

    const isSelectedObject: boolean = useMemo(() => {
        return (
            selectedObjectIds.findIndex((s: string) => {
                return s === object.id
            }) !== -1
        )
    }, [object, selectedObjectIds])

    // active child objects when ONE object is selected
    const { activeChildObjectIds } = useWrapSelectedObject()
    const isChildObjectActive = useMemo(() => {
        return (
            activeChildObjectIds.findIndex((childId: string) => {
                return childId === object.id
            }) !== -1
        )
    }, [activeChildObjectIds, object])

    //active Child objects ids when MORE objects are selected
    const { activeChildObjectsIds } = useWrapSelectedObjects()
    const isChildObjectsActive = useMemo(() => {
        return (
            activeChildObjectsIds.findIndex((childId: string) => {
                return childId === object.id
            }) !== -1
        )
    }, [activeChildObjectsIds, object])

    const handleMoveObjectUp = () => moveObjectUp(object)
    const handleMoveObjectDown = () => moveObjectDown(object)

    const { handleMultiselectObject } = useMultiselectObject(object)
    const { handleDuplicateObjects, handleGroupObjects, handleDeleteObjects } =
        useMultiselectObjectAction()

    const handleSelectObject = (CtrlCmdKey: boolean) => {
        handleMultiselectObject(CtrlCmdKey)
    }

    const handleAddChildItem = () => addChildItem(object.id)
    const handleAddChildText = () => addChildText(object.id)
    const handleDuplicateObject = () => {
        dispatch(duplicateObjects([object]))
    }
    const handleUnGroupObjects = () => {
        dispatch(unGroupObjectsAction({ object }))
    }

    const { addScrollDownTicker, addScrollUpTicker, addScrollUpDownTicker } =
        useTickerCreateAction()
    const { addCrawlContinuousTicker, addCrawlStaggeredTicker } = useTickerCreateAction()

    //move object to root layer of objects
    const handleMoveToRoot = () => {
        const selectedObject: AnySceneObjectT | undefined = findObjectById(selectedObjectIds[0])
        if (selectedObject) dispatch(moveSelectedToRootAction({ object: selectedObject }))
    }

    //Add child under parent || move selected object under new parent object
    const handleMoveSelectedHere = () => {
        if (!selectedObjectIds[0]) return
        const selectedObject: AnySceneObjectT | undefined = findObjectById(selectedObjectIds[0])
        if (selectedObject)
            dispatch(moveSelectedHereAction({ object: selectedObject, parent: object }))
    }

    const handleDeleteObject = () => {
        deleteObject(object)
    }

    // reference to the hidden file input element
    const hiddenSequenceFileInput: MutableRefObject<HTMLInputElement | null> = React.useRef(null)
    const hiddenImageFileInput: MutableRefObject<HTMLInputElement | null> = React.useRef(null)

    const handleReadSequence = async (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoading(true))
        await readImageSequence(e.target.files!, object.id)
        dispatch(setLoading(false))
    }

    const handleReadImage = async (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setLoading(true))
        await readImageFile(e.target.files?.[0]!, object.id)
        dispatch(setLoading(false))
    }

    const getObjectMenuItems = (): MenuItemT[] => {
        const isParentObject = isParentObjectType(object.type)
        const isImage = object.type === 'image' || object.type === 'sequence'
        const menuItems: MenuItemT[] =
            selectedObjectIds.length <= 1
                ? [
                      {
                          icon: <RectangleOutlinedIcon />,
                          title: t('editor:addNewRectangle'),
                          onClick: handleAddChildItem,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <TextFieldsOutlinedIcon />,
                          title: t('editor:addNewText'),
                          onClick: handleAddChildText,
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ImageIcon />,
                          title: t('editor:addNewImage'),
                          onClick: () => hiddenImageFileInput.current?.click(),
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ImageIcon />,
                          title: t('editor:insertImageUrl'),
                          onClick: () => setOpenImageSrcDialog(true),
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ImageIcon />,
                          title: t('editor:chooseImageAsset'),
                          onClick: () => setAssetType('image'),
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <CollectionsIcon />,
                          title: t('editor:addNewSequence'),
                          onClick: () => hiddenSequenceFileInput.current?.click(),
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <CollectionsIcon />,
                          title: t('editor:chooseSequenceAsset'),
                          onClick: () => setAssetType('sequence'),
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArticleIcon />,
                          title: t('editor:tickers.addScrollDown'),
                          onClick: () => addScrollDownTicker(object.id),
                          divider: false,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArticleIcon />,
                          title: t('editor:tickers.addScrollUp'),
                          onClick: () => addScrollUpTicker(object.id),
                          divider: false,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArticleIcon />,

                          title: t('editor:tickers.addScrollUpDown'),
                          onClick: () => addScrollUpDownTicker(object.id),
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArticleIcon />,
                          title: t('editor:tickers.addCrawlContinuous'),
                          onClick: () => addCrawlContinuousTicker(object.id),
                          divider: false,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArticleIcon />,
                          title: t('editor:tickers.addCrawlStaggered'),
                          onClick: () => addCrawlStaggeredTicker(object.id),
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <LayersClearIcon />,
                          title: t('editor:Ungroup'),
                          shortCut: isWindows ? 'Ctrl Shift G' : '⇧ ⌘ G',
                          onClick: handleUnGroupObjects,
                          hidden: !object.isGroup,
                      },
                      {
                          icon: <LayersIcon />,
                          title: t('editor:group'),
                          shortCut: isWindows ? 'Ctrl G' : '⌘ G',
                          onClick: handleGroupObjects,
                      },
                      {
                          icon: <ContentCopyIcon />,
                          title: t('editor:duplicate'),
                          shortCut: isWindows ? 'Ctrl D' : '⌘ D',
                          onClick: handleDuplicateObject,
                          divider: true,
                      },
                      {
                          icon: <ArrowUpwardIcon />,
                          title: t('editor:moveUp'),
                          shortCut: isWindows ? 'Ctrl Shift ▲' : '⇧ ⌘ ▲', //alternative 'Ctrl Shift ↑' : '⇧ ⌘ ↑'
                          onClick: handleMoveObjectUp,
                          disabled: object.index === 0 ? true : false,
                      },
                      {
                          icon: <ArrowDownwardIcon />,
                          title: t('editor:moveDown'),
                          shortCut: isWindows ? 'Ctrl Shift ▼' : '⇧ ⌘ ▼', //alternative 'Ctrl Shift ↓' | '⇧ ⌘ ↓'
                          onClick: handleMoveObjectDown,
                          disabled: object.index < numberOfObjects - 1 ? false : true,
                      },
                      {
                          icon: <ArrowBackRoundedIcon />,
                          title: t('editor:moveToTheRoot'),
                          onClick: handleMoveToRoot,
                          disabled: !object.parentId,
                          hidden: !isParentObject,
                      },
                      {
                          icon: <SubdirectoryArrowRightRoundedIcon />,
                          title: t('editor:moveSelectedHere'),
                          onClick: handleMoveSelectedHere,
                          disabled:
                              object.id === activeObjectId ||
                              selectedObjectIds.length === 0 ||
                              selectedObjectIds.length > 1,
                          divider: true,
                          hidden: !isParentObject || isImage,
                      },
                      {
                          icon: <ArrowBackRoundedIcon />,
                          title: t('editor:moveToTheRoot'),
                          onClick: handleMoveToRoot,
                          disabled: !object.parentId,
                          divider: true,
                          hidden: isParentObject,
                      },
                      {
                          icon: <DeleteRoundedIcon />,
                          title: t('editor:delete'),
                          shortCut: isWindows ? 'Ctrl Del' : '⌘ ⌫',
                          onClick: handleDeleteObject,
                      },
                  ]
                : [
                      {
                          icon: <LayersIcon />,
                          title: t('editor:groupSelected'),
                          shortCut: isWindows ? 'Ctrl G' : '⌘ G',
                          onClick: handleGroupObjects,
                      },
                      {
                          icon: <ContentCopyIcon />,
                          title: t('editor:duplicateSelected'),
                          shortCut: isWindows ? 'Ctrl D' : '⌘ D',
                          onClick: handleDuplicateObjects,
                          divider: true,
                      },
                      {
                          icon: <DeleteRoundedIcon />,
                          title: t('editor:deleteSelected'),
                          shortCut: isWindows ? 'Ctrl Del' : '⌘ ⌫',
                          onClick: handleDeleteObjects,
                      },
                  ]

        return menuItems.filter((item) => !item.hidden)
    }

    const { readImageSequence, readImageFile } = useObjectActions()

    return (
        <div>
            <RightClickContextMenu
                menuLabel={
                    <ObjectTitle
                        index={object.index}
                        title={object.title}
                        isActive={isSelectedObject || isChildObjectActive || isChildObjectsActive}
                        handleSelectObject={handleSelectObject}
                    />
                }
                data={getObjectMenuItems()}
            />
            <LoadImageAction hiddenFileInputRef={hiddenImageFileInput} onChange={handleReadImage} />
            <ImageSrcDialog
                open={openImageSrcDialog}
                onClose={() => setOpenImageSrcDialog(false)}
                parentId={object.id}
            />
            <LoadImageAction
                hiddenFileInputRef={hiddenSequenceFileInput}
                onChange={handleReadSequence}
                multiple={true}
            />
            <AssetsDialog
                open={Boolean(assetType)}
                onClose={() => setAssetType(undefined)}
                parentId={object.id}
                type={assetType!}
            />
        </div>
    )
}

export default ObjectsTreeNodeMenu
