import { PayloadAction } from '@reduxjs/toolkit'
import { ObjectsStateT } from '../../objects.slice'

export const unGroupObjectsBaseAction = (
    state: ObjectsStateT,
    action: PayloadAction<{ object: AnySceneObjectT }>
) => {
    const object = action.payload.object
    const groupLeftStyleValue = object.styles.find((style) => style.property === 'left')?.value ?? 0
    const groupTopStyleValue = object.styles.find((style) => style.property === 'top')?.value ?? 0
    const parentObject = object.parentId && state.value[object.parentId]
    state.value = {
        ...state.value,
        [object.id]: { ...state.value[object.id], childIds: [] },
    }

    if (parentObject && parentObject.isGroup) {
        object.childIds.forEach((childId) => {
            const styles = state.value[childId].styles
            const topStyle: SimpleStyleT | undefined = styles.find(
                (style) => style.property === 'top'
            )
            const leftStyle: SimpleStyleT | undefined = styles.find(
                (style) => style.property === 'left'
            )
            const restStyles = styles.filter(
                (style) => style.property !== 'top' && style.property !== 'left'
            )
            if (!topStyle || !leftStyle) return state.value

            state.value = {
                ...state.value,
                [childId]: {
                    ...state.value[childId],
                    parentId: parentObject.id,
                    styles: [
                        ...restStyles,
                        {
                            ...topStyle,
                            value: Number(topStyle.value) + Number(groupTopStyleValue),
                        },
                        {
                            ...leftStyle,
                            value: Number(leftStyle.value) + Number(groupLeftStyleValue),
                        },
                    ],
                },
                [parentObject.id]: {
                    ...state.value[parentObject.id],
                    childIds: [
                        ...state.value[parentObject.id].childIds.filter((x) => x !== object.id),
                        state.value[childId].id,
                    ],
                },
            }
        })
    } else {
        object.childIds.forEach((childId) => {
            const styles = state.value[childId].styles
            const topStyle: SimpleStyleT | undefined = styles.find(
                (style) => style.property === 'top'
            )
            const leftStyle: SimpleStyleT | undefined = styles.find(
                (style) => style.property === 'left'
            )
            const restStyles = styles.filter(
                (style) => style.property !== 'top' && style.property !== 'left'
            )
            if (!topStyle || !leftStyle) return state.value

            state.value = {
                ...state.value,
                [childId]: {
                    ...state.value[childId],
                    parentId: null,
                    isLocked: false,
                    styles: [
                        ...restStyles,
                        {
                            ...topStyle,
                            value: Number(topStyle.value) + Number(groupTopStyleValue),
                        },
                        {
                            ...leftStyle,
                            value: Number(leftStyle.value) + Number(groupLeftStyleValue),
                        },
                    ],
                },
            }
        })
    }
    delete state.value[object.id]
    state.updatedAt = Date.now()
}
